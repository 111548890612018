import {BasicsClass} from "@/common/BasicsClass";
import {Util} from "@/common/util";

export default class MembershisAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['record', {
            url: '/member/record',
            name: 'record',
            label: '列表'
        }],
        ['buy', {
            url: '/member/buy',
            name: 'buy',
            label: '购买会员'
        }],
        ['request', {
            url: '/pay/request',
            name: 'pay-request',
            label: '支付请求'
        }],
        ['query', {
            url: '/pay/query',
            name: 'query',
            label: '查询支付订单'
        }],
    ])

    public memberrecord(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('record').url
        const start_date: string = Util.handleStartTimeAndEndTime(params.time, false).startTime
        const end_date: string = Util.handleStartTimeAndEndTime(params.time, false).endTime
        this.BasicPost(
            url,
            {
                ...params,
                start_date: start_date,
                end_date: end_date
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public memberbuy(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('buy').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public payrequest(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('request').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public payquery(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('query').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

}
